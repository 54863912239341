import React from 'react';
import HTMLFlipBook from "react-pageflip";
import {appConst} from "../constant/appConst";
import '../css/Brochure.css';
import {Link} from "react-router-dom";

function Brochure() {
    const brochure = appConst.brochure;

  return (


        <div className="flipbook-container">
            <HTMLFlipBook width={1200}
                          height={675}
                          size="stretch"
                          minWidth={300}
                          maxWidth={1654}
                          minHeight={1165}
                          maxHeight={250} >

                {
                    brochure.map((b,i) => (
                        <div className="flipbook-page" key={i}><img src={b.image} /></div>
                    ))
                }
            </HTMLFlipBook>
            <Link className={"default-button lined download-link"} to={"/assets/pdf/brochure.pdf"} target="_blank" rel="noopener noreferrer">DOWNLOAD BROCHURE</Link>
        </div>



  );
}

export default Brochure;



