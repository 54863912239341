import React, {useContext, useEffect, useState} from 'react';
import SubMenuItem from "./SubMenuItem";
import '../css/SubMenu.css';
import '../css/LocationSubMenu.css';
import {appConst, appSettings} from "../constant/appConst";
import SubMenuTitle from "./SubMenuTitle";
import LocationContext from "./context/LocationContext";
import {useHistory, useLocation} from "react-router-dom";
import UserContext from "./context/UserContext";
const LocationSubMenu = ({locations,onClickButtonBack}) => {
    const {locationCategory,setLocationCategory} = useContext(LocationContext);
    const history = useHistory();
    function changeActiveCategory(categoryId) {
        setLocationCategory(categoryId)
    }

    function realShot(){
        history.push("/facility/8")
    }
    return (
        <div className="location-menu side-menu">
            <div className="inner">
                <div className={"title"}>
                    <h2>LOCATION</h2>
                </div>
                { appSettings.hasRealShot && <div className="view-container">
                    <button className={"btn-mapview active"}><span className="icon"></span>MAP VIEW</button>
                    <button className={"btn-real-shooting"} onClick={realShot}><span className="icon"></span>REAL SHOOT</button>
                </div>}

                <div className="location-cat-container">
                    <div className="row no-gutters">
                        <div className="col-6">
                            <div className="location-cat-item" key={"all"}>
                                <div className="form-group" >
                                    <label className="check-container mb-1">{"All"}
                                        <input type="radio" id={"all"} name="location_category" value={"all"} checked={locationCategory === "all"} onChange={()=> {changeActiveCategory("all")}} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                            {appConst.menu.location_category.map(item => (
                                <div className={item.id === "developments" ? "col-12":"col-6"} key={item.id}>
                                <div className="location-cat-item" >
                                    <div className="form-group" >
                                        <label className="check-container mb-1">{item.title}
                                            <input type="radio" id={item.title} name="location_category" value={item.id} checked={locationCategory === item.id} onChange={()=> {changeActiveCategory(item.id)}} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                            ))}

                    </div>

                </div>
                <div className="location-list">

                    {locationCategory && appConst.menu.location_category.map(function(item) {
                        if(locationCategory === "all")
                            return (
                                <div key={item.id}>
                                    <SubMenuTitle title={item.title} />
                                    {
                                        appConst.menu.location[item.id].map((sub,i) => (<SubMenuItem title={sub.title} link={sub.link} key={sub.id} />))
                                    }
                                </div>
                            )
                        else
                        if(locationCategory === item.id)
                            return (
                                <div key={item.id}>
                                    <SubMenuTitle title={item.title} />
                                    {
                                        appConst.menu.location[item.id].map((sub,i) => (<SubMenuItem title={sub.title} link={sub.link} key={sub.id} />))
                                    }
                                </div>
                            )

                    })}


                </div>
            </div>

        </div>
    );
};

export default LocationSubMenu;