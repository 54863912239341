import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {Link, useHistory,useParams} from "react-router-dom";
import "../css/FormResult.css";



function FormResult() {

  const {message} = useParams();
  const [messageIcon,setMessageIcon] = useState("check");
  const [messageText,setMessageText] = useState(null);
  const { user, setUser } = useContext(UserContext);


  useEffect(()=> {
    if(message === "forgotPassword") {
        setMessageIcon("check");
        setMessageText("Your reset link sent successfully to your email address");
    }

    else if(message === "invalidToken") {
      setMessageIcon("fail");
      setMessageText("Reset link is invalid. Please try forgot password again for a new link");
    }
    else if(message === "passwordUpdate") {
      setMessageIcon("check");
      setMessageText("Your password updated successfully");
    }
    else if(message === "invalidCode") {
      setMessageIcon("fail");
      setMessageText("Your verification code is not valid. Try to login");
    }
    else if(message === "thankYouRegister") {
      setMessageIcon("check");
      setMessageText("Thank you for registering - We will be contact you as soon as possible");
    }
    else if(message === "invitationAccepted") {
      setMessageIcon("check");
      setMessageText("You accepted the invitation. Now you can take a tour with your sales person");
    }
    else if(message === "loginAccepted") {
      setMessageIcon("check");
      setMessageText("Verification is successfull. Now you can close this window.");
    }
    else {
       setMessageText("");
    }
  },[]);


  return (
    <section  className="sign-section">
      {(messageText && messageIcon) && <div className="sign-wrap">

        <div className="inner">
          <div className={"text-center"}>{messageIcon && <span className={"message-icon " + messageIcon}/>}</div>
          <h2 className="mt-3">{messageIcon === "check" ? "SUCCESS" : "FAILED" }</h2>
          <p className="mt-3 text-center">{ messageText}</p>

          <div className="mt-4">
            {
              (()=>{
                if(!(message === "invitationAccepted" || message === "loginAccepted")) {
                  if(user) return <Link className={"default-button lined login"} to="/inviteClient">INVITE CLIENT</Link>;
                  if(!user) return <Link className={"default-button lined login"} to="/">GO HOME</Link>
                }

              })()
            }


          </div>

        </div>

      </div>}

      {!user && <Link to="/salesLogin" className="sales-login-link">Login as Sales Person</Link>}
      {user && <Link to="/" className="sales-login-link">Go Home</Link>}
    </section>
  );
}

export default FormResult;