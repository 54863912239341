export const propertyConst = {
    slider : {
        "type_a" : [
            {source : require("../img/property_slider/type_a/i1.jpg")},
            {source : require("../img/property_slider/type_a/i2.jpg")},
            {source : require("../img/property_slider/type_a/i3.jpg")},
            {source : require("../img/property_slider/type_a/i4.jpg")},
            {source : require("../img/property_slider/type_a/i5.jpg")},
            {source : require("../img/property_slider/type_a/i6.jpg")},

        ],

        "type_b" : [
            {source : require("../img/property_slider/type_b/i1.jpg")},
            {source : require("../img/property_slider/type_b/i2.jpg")},
            {source : require("../img/property_slider/type_b/i3.jpg")},
            {source : require("../img/property_slider/type_b/i4.jpg")},
            {source : require("../img/property_slider/type_b/i5.jpg")},
            {source : require("../img/property_slider/type_b/i6.jpg")},

        ],

        "type_c" : [
            {source : require("../img/property_slider/type_c/i1.jpg")},
            {source : require("../img/property_slider/type_c/i2.jpg")},
            {source : require("../img/property_slider/type_c/i3.jpg")},
            {source : require("../img/property_slider/type_c/i4.jpg")},
            {source : require("../img/property_slider/type_c/i5.jpg")},
            {source : require("../img/property_slider/type_c/i6.jpg")},
            {source : require("../img/property_slider/type_c/i7.jpg")},

        ],

        "type_d" : [
            {source : require("../img/property_slider/type_d/i1.jpg")},
            {source : require("../img/property_slider/type_d/i2.jpg")},
            {source : require("../img/property_slider/type_d/i3.jpg")},
            {source : require("../img/property_slider/type_d/i4.jpg")},
            {source : require("../img/property_slider/type_d/i5.jpg")},
            {source : require("../img/property_slider/type_d/i6.jpg")},
            {source : require("../img/property_slider/type_d/i7.jpg")},
            {source : require("../img/property_slider/type_d/i8.jpg")},

        ],

        "townhouse" : [
            {source : require("../img/property_slider/townhouse/i1.jpg")},
            {source : require("../img/property_slider/townhouse/i2.jpg")},
            {source : require("../img/property_slider/townhouse/i3.jpg")},
            {source : require("../img/property_slider/townhouse/i4.jpg")},
            {source : require("../img/property_slider/townhouse/i5.jpg")},
            {source : require("../img/property_slider/townhouse/i6.jpg")},
        ],



    },
    thumb : {
        "type_a" : [
            {source : require("../img/property_thumbs/type_a/i1.jpg")},
        ],

        "type_b" : [
            {source : require("../img/property_thumbs/type_b/i1.jpg")},


        ],

        "type_c" : [
            {source : require("../img/property_thumbs/type_c/i1.jpg")},


        ],

        "type_d" : [
            {source : require("../img/property_thumbs/type_d/i1.jpg")},


        ],

        "townhouse" : [
            {source : require("../img/property_thumbs/townhouse/i1.jpg")},

        ],

    },
    floorplan_slider : {

        "m1" : [
            {source : require("../img/property_floorplan/m1/i1.jpg")},
            {source : require("../img/property_floorplan/m1/i2.jpg")},
        ],

        "m2" : [
            {source : require("../img/property_floorplan/m2/i1.jpg")},
            {source : require("../img/property_floorplan/m2/i2.jpg")},
        ],

        "m3" : [
            {source : require("../img/property_floorplan/m3/i1.jpg")},
            {source : require("../img/property_floorplan/m3/i2.jpg")},
        ],

        "m4" : [
            {source : require("../img/property_floorplan/m4/i1.jpg")},
            {source : require("../img/property_floorplan/m4/i2.jpg")},
        ],

        "m5" : [
            {source : require("../img/property_floorplan/m5/i1.jpg")},
            {source : require("../img/property_floorplan/m5/i2.jpg")},
        ],

        "m6" : [
            {source : require("../img/property_floorplan/m6/i1.jpg")},
            {source : require("../img/property_floorplan/m6/i2.jpg")},
        ],

        "m7" : [
            {source : require("../img/property_floorplan/m7/i1.jpg")},
            {source : require("../img/property_floorplan/m7/i2.jpg")},
        ],

        "m8" : [
            {source : require("../img/property_floorplan/m8/i1.jpg")},
            {source : require("../img/property_floorplan/m8/i2.jpg")},
        ],

        "m9" : [
            {source : require("../img/property_floorplan/m9/i1.jpg")},
            {source : require("../img/property_floorplan/m9/i2.jpg")},
        ],

        "m10" : [
            {source : require("../img/property_floorplan/m10/i1.jpg")},
            {source : require("../img/property_floorplan/m10/i2.jpg")},
        ],

        "m11" : [
            {source : require("../img/property_floorplan/m11/i1.jpg")},
            {source : require("../img/property_floorplan/m11/i2.jpg")},
        ],

        "m12" : [
            {source : require("../img/property_floorplan/m12/i1.jpg")},
            {source : require("../img/property_floorplan/m12/i2.jpg")},
        ],

        "m13" : [
            {source : require("../img/property_floorplan/m13/i1.jpg")},
            {source : require("../img/property_floorplan/m13/i2.jpg")},
        ],

        "m14" : [
            {source : require("../img/property_floorplan/m14/i1.jpg")},
            {source : require("../img/property_floorplan/m14/i2.jpg")},
        ],

        "m15" : [
            {source : require("../img/property_floorplan/m15/i1.jpg")},
            {source : require("../img/property_floorplan/m15/i2.jpg")},
        ],

        "m16" : [
            {source : require("../img/property_floorplan/m16/i1.jpg")},
            {source : require("../img/property_floorplan/m16/i2.jpg")},
        ],

        "m17" : [
            {source : require("../img/property_floorplan/m17/i1.jpg")},
            {source : require("../img/property_floorplan/m17/i2.jpg")},
        ],

        "m18" : [
            {source : require("../img/property_floorplan/m18/i1.jpg")},
            {source : require("../img/property_floorplan/m18/i2.jpg")},
        ],

        "m19" : [
            {source : require("../img/property_floorplan/m19/i1.jpg")},
            {source : require("../img/property_floorplan/m19/i2.jpg")},
        ],

        "m20" : [
            {source : require("../img/property_floorplan/m20/i1.jpg")},
            {source : require("../img/property_floorplan/m20/i2.jpg")},
        ],

        "m21" : [
            {source : require("../img/property_floorplan/m21/i1.jpg")},
            {source : require("../img/property_floorplan/m21/i2.jpg")},
        ],

        "m22" : [
            {source : require("../img/property_floorplan/m22/i1.jpg")},
            {source : require("../img/property_floorplan/m22/i2.jpg")},
        ],

        "m23" : [
            {source : require("../img/property_floorplan/m23/i1.jpg")},
            {source : require("../img/property_floorplan/m23/i2.jpg")},
        ],

        "m24" : [
            {source : require("../img/property_floorplan/m24/i1.jpg")},
            {source : require("../img/property_floorplan/m24/i2.jpg")},
        ],

        "m25" : [
            {source : require("../img/property_floorplan/m25/i1.jpg")},
            {source : require("../img/property_floorplan/m25/i2.jpg")},
        ],

        "m26" : [
            {source : require("../img/property_floorplan/m26/i1.jpg")},
            {source : require("../img/property_floorplan/m26/i2.jpg")},
        ],

        "v1" : [
            {source : require("../img/property_floorplan/v1/i1.jpg")},
        ],

        "v2" : [
            {source : require("../img/property_floorplan/v2/i1.jpg")},
        ],

        "v3" : [
            {source : require("../img/property_floorplan/v3/i1.jpg")},
        ],

        "v4" : [
            {source : require("../img/property_floorplan/v4/i1.jpg")},
        ],

        "v5" : [
            {source : require("../img/property_floorplan/v5/i1.jpg")},
            {source : require("../img/property_floorplan/v5/i2.jpg")},
        ],

        "v6" : [
            {source : require("../img/property_floorplan/v6/i1.jpg")},
        ],

        "v7" : [
            {source : require("../img/property_floorplan/v7/i1.jpg")},
        ],

        "v8" : [
            {source : require("../img/property_floorplan/v8/i1.jpg")},
        ],

        "v9" : [
            {source : require("../img/property_floorplan/v9/i1.jpg")},
        ],

        "v10" : [
            {source : require("../img/property_floorplan/v10/i1.jpg")},
        ],

        "v11" : [
            {source : require("../img/property_floorplan/v11/i1.jpg")},
        ],

        "v12" : [
            {source : require("../img/property_floorplan/v12/i1.jpg")},
        ],

        "v13" : [
            {source : require("../img/property_floorplan/v13/i1.jpg")},
        ],

        "v14" : [
            {source : require("../img/property_floorplan/v14/i1.jpg")},
        ],

        "v15" : [
            {source : require("../img/property_floorplan/v15/i1.jpg")},
        ],

        "v16" : [
            {source : require("../img/property_floorplan/v16/i1.jpg")},
        ],

        "v17" : [
            {source : require("../img/property_floorplan/v17/i1.jpg")},
        ],

        "v18" : [
            {source : require("../img/property_floorplan/v18/i1.jpg")},
        ],

        "v19" : [
            {source : require("../img/property_floorplan/v19/i1.jpg")},
        ],

        "v20" : [
            {source : require("../img/property_floorplan/v20/i1.jpg")},
        ],

        "v21" : [
            {source : require("../img/property_floorplan/v21/i1.jpg")},
        ],

        "v22" : [
            {source : require("../img/property_floorplan/v22/i1.jpg")},
        ],

        "v23" : [
            {source : require("../img/property_floorplan/v23/i1.jpg")},
        ],

        "v24" : [
            {source : require("../img/property_floorplan/v24/i1.jpg")},
        ],

        "v25" : [
            {source : require("../img/property_floorplan/v25/i1.jpg")},
        ],

        "v26" : [
            {source : require("../img/property_floorplan/v26/i1.jpg")},
        ],

        "v27" : [
            {source : require("../img/property_floorplan/v27/i1.jpg")},
        ],

        "v28" : [
            {source : require("../img/property_floorplan/v28/i1.jpg")},
        ],

        "v29" : [
            {source : require("../img/property_floorplan/v29/i1.jpg")},
        ],

        "v30" : [
            {source : require("../img/property_floorplan/v30/i1.jpg")},
        ],

        "v31" : [
            {source : require("../img/property_floorplan/v31/i1.jpg")},
        ],

        "v32" : [
            {source : require("../img/property_floorplan/v32/i1.jpg")},
        ],

        "v33" : [
            {source : require("../img/property_floorplan/v33/i1.jpg")},
        ],



    },

    vr360 : {
        "1st_floor_studio_b" : "https://kuula.co/share/collection/7JZl1?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1/",
        "1st_floor_studio_c" : "https://kuula.co/share/collection/7JZlG?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_1_bed" : "https://kuula.co/share/collection/7JZlN?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_a" : "https://kuula.co/share/collection/7JZl6?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_b" : "https://kuula.co/share/collection/7JZlw?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_c" : "https://kuula.co/share/collection/7JZly?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "island_villa" : "https://kuula.co/share/collection/7JbTQ?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "townhouse" : "https://kuula.co/share/5lkhF/collection/7JZP5?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",

    }
}