import {appSettings} from "./appConst";

export const appUtil = {
    formatPrice : function(price){
        const n = 0,x = 3, s=".", c=",";
            let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = price.toFixed(Math.max(0, ~~n));
            let currency = appSettings.currencyUnit;
            if (appSettings.currencyUnitPosition == "before") {
                return currency + (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ',')) + " + VAT";
            }
            else {
                return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ',')) + currency + " + VAT";
            }
            

    },

    isEmpty : function (obj) {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    },
    trimString : function(str,charLength){

        return str.replace(new RegExp("^(.{"+charLength+"}[^\s]*).*"), "$1");
    },
    convertToSlug : function (text)
    {
        return text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    },
    convertSecondsToMinutes : function(seconds){
        let minutes = 0;
        if(seconds > 59) {
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
        }
        return minutes + " : " + seconds;
    },

    containsObject : function (obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return true;
            }
        }
        return false;
    },

    dynamicSort : function (property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },

    convertStringToDate : function(dateStr){
        return new Date(dateStr).toDateString();
    },

    isSelfPresentation : function (){
        if(sessionStorage.getItem("presentation_status") !== null) {
            return !parseInt(sessionStorage.getItem("presentation_status"));
        }
        return false;
    }
}