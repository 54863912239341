export const buildingConst = {
    "ac-2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1314 517.5 1328 517.5 1334 542.5 1806 511.5 1857 477.5 1875 476.5 1876 715.5 1834 721.5 1834 811.5 1921 813.5 1922 881.5 1316 863.5 1320 729.5 1315 721.5 1314 517.5"},
            {status : 1, level: 1,points:"793 538.5 803 537.5 845 554.5 1052 539.5 1052 516.5 1064 515.5 1172 600.5 1172 855.5 793 833.5 793 538.5"},
            {status : 1, level: 2,points:"613 547 753 589 751.5 796.5 521.5 808.5 429 799 261 797 261 660 353 655 353 569 613 547"},
            {status : 1, level: 3,points:"169 584 353 569 353 655 261 660 261 797 75 796 75 670 170 667 169 584"},


        ]
    },

    "dc": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1452.53,817.51 1827.72,761.74 1827.72,591.15 1696.5,579.76 1696.5,474.09 1267.95,389.48 1146.92,412.27 1146.92,756.39 1452.53,756.39"},
            {status : 1, level: 1,points:"490.63,207.84 202.11,408.3 202.11,881.74 490.63,924.32 1452.53,817.51 1452.53,756.39 1146.92,756.39 1146.92,347.18 1134.49,344.59 1035.03,368.42 514.97,272.59 514.97,213.02"},
        ]
    },

    "a-2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"310.81,922.19 310.81,691.31 75.81,681.94 75.81,334.44 557.06,138.19 581.44,144.44 581.44,211.94 905.19,301.94 997.06,283.81 1013.31,288.19 1013.31,730.06 1218.94,730.06 1218.94,850.69"},
            {status : 1, level: 1,points:"1013.31,435.06 1238.34,402.09 1247.67,404.16 1247.67,440.42 1326.4,460.1 1408.24,451.81 1414.16,453.07 1414.16,743.25 1536.18,745.25 1536.18,836.94 1218.94,850.69 1218.94,730.06 1013.31,728.06"},
            {status : 1, level: 2,points:"1414.16,520.01 1519.18,512.07 1523.49,513.8 1523.49,536.07 1534.03,538.49 1609.13,534.35 1612.59,535.55 1612.59,751.42 1699.09,752.42 1699.09,828.22 1536.18,836.94 1536.18,745.25 1414.16,745.25"},
            {status : 1, level: 3,points:"1612.59,574.23 1673.71,571.81 1676.47,573.02 1676.47,585.28 1728.79,583.55 1731.21,585.28 1731.21,777.07 1804.41,776.07 1804.41,821.65 1699.09,828.22 1699.09,752.42 1612.59,752.42"},
            {status : 1, level: 4,points:"1731.21,628.06 1769.92,627.28 1772.51,628.06 1772.51,632.59 1807.61,632.59 1809.55,633.11 1809.55,784.75 1867.82,784.75 1867.82,818.48 1804.41,821.65 1804.41,776.07 1731.21,777.07"},
            {status : 1, level: 5,points:"1867.82,784.75 1809.55,784.75 1809.55,650.5 1836.57,650.5 1838.43,650.86 1838.43,653.34 1863.37,653.34 1865.32,653.97 1865.32,788.26 1902.16,788.26 1902.16,816.78 1867.82,818.48"},
        ]
    },

    "b": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1233.31,894.44 1233.31,837.81 950.81,838.81 950.81,273.19 933.94,268.19 863.31,310.06 252.06,113.19 252.06,25.06 212.69,10.06 140.81,447.56 140.81,873.81 243.81,1036.94"},
            {status : 1, level: 1,points:"1233.31,894.44 1662.31,845.65 1662.31,805.94 1506.23,805.94 1506.23,460.27 1499.67,456.82 1428.53,472.01 1284.18,425.05 1284.53,375.67 1273.13,373.25 950.81,485.48 950.81,838.81 1233.31,838.81"},
            {status : 1, level: 2,points:"1662.31,845.65 1864.85,814.4 1864.85,782.03 1751.67,782.03 1751.67,538.58 1746.23,538.58 1688.47,547.64 1643.67,532.36 1643.67,498.17 1637.97,497.14 1506.23,522.26 1506.23,805.94 1662.31,805.94"},
            {status : 1, level: 3,points:"1864.85,814.4 1918.72,803.01 1918.72,782.42 1889.58,782.42 1889.58,580.27 1887.25,578.33 1840.76,584.03 1826.38,579.37 1826.38,553.6 1822.89,551.53 1751.67,561.37 1751.67,782.03 1864.85,782.03"},
        ]
    },

    "bc": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"263.94,12.56 263.94,1043.81 1212.69,910.06 1212.69,828.19 942.69,828.19 942.69,301.94 927.06,293.19 859.56,330.69 299.56,120.69 299.56,27.56"},
            {status : 1, level: 1,points:"1212.69,910.06 1581.16,865.34 1581.16,819.76 1435.09,819.76 1435.09,499.29 1427.49,497.22 1365.33,510 1240.67,462 1240.67,414.69 1231.35,409.86 942.69,500.33 942.69,828.19 1212.69,828.19"},
            {status : 1, level: 2,points:"1581.16,865.34 1726.54,853.77 1726.54,820.36 1645.74,820.36 1645.74,587.27 1641.33,585.45 1591.87,592.45 1554.05,576.91 1554.05,545.83 1548.62,543.24 1435.09,563.44 1435.09,819.76 1581.16,819.76"},
            {status : 1, level: 3,points:"1726.54,853.77 1834.11,841.51 1834.11,820.96 1772.61,820.96 1772.61,646.75 1768.85,645.54 1726.54,649.34 1698.23,639.67 1698.23,613.94 1694.6,612.91 1645.74,618.6 1645.74,820.36 1726.54,820.36"},
            {status : 1, level: 4,points:"1834.11,841.51 1896.1,833.74 1896.1,816.65 1871.75,816.65 1871.75,681.8 1860.7,681.8 1860.7,676.27 1857.42,675.58 1823.75,678 1816.15,674.72 1816.15,656.24 1813.39,654.69 1772.61,658.49 1772.61,820.96 1834.11,820.96"},
        ]
    },

    "a-1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1878.49,766.75 1905.08,765.37 1905.08,745.51 1871.23,745.51 1871.23,627.93 1844.3,628.79 1844.3,620.68 1819.61,622.4 1819.61,739.29 1878.49,741.54"},
            {status : 1, level: 1,points:"1815.46,771.58 1878.49,766.75 1878.49,741.54 1819.61,739.29 1819.61,606.35 1816.85,606.35 1783.69,608.42 1783.69,595.81 1780.93,595.81 1742.77,598.23 1742.77,746.03 1815.46,746.03"},
            {status : 1, level: 2,points:"1717.74,781.94 1815.46,771.58 1815.46,746.03 1742.77,746.03 1742.77,581.48 1740.01,581.48 1692.7,586.66 1688.21,584.94 1688.21,566.46 1685.28,566.46 1621.39,572.16 1621.39,746.03 1717.74,751.55"},
            {status : 1, level: 3,points:"1543.87,802.49 1717.74,781.94 1717.74,751.55 1621.39,746.03 1621.39,539.53 1618.28,539.53 1562.17,546.6 1528.85,537.8 1528.85,512.59 1524.53,512.59 1401.94,529.51 1401.94,760.36 1543.87,767.96"},
            {status : 1, level: 4,points:"1126.44,852.56 1543.87,802.49 1543.87,767.96 1401.94,760.36 1401.94,473.05 1395.72,473.05 1329.08,485.48 1207.18,453.71 1207.18,415.38 1197.51,412.96 866.44,510 866.44,690.69 901.44,690.69 901.44,785.06 1126.44,791.94"},
            {status : 1, level: 5,points:"144.56,60.69 144.56,987.56 1126.44,852.56 1126.44,791.94 901.44,785.06 901.44,690.69 866.44,690.69 866.44,290.06 849.56,285.06 790.19,320.69 185.19,153.19 186.44,71.31"},
        ]
    },

    "ac-1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1855.95,854.68 1915.78,850.66 1915.78,826.92 1866.66,826.92 1866.66,721.17 1864.33,720.65 1832.82,721.94 1832.82,714.6 1830.92,713.57 1814.86,713.91 1814.86,827.61 1855.95,827.61"},
            {status : 1, level: 1,points:"1744.03,691.81 1779.38,690.6 1780.85,691.81 1780.85,700.01 1813.05,699.24 1814.86,700.01 1814.86,827.61 1855.95,827.61 1855.95,854.68 1813.05,857.4 1813.05,831.37 1744.03,831.37"},
            {status : 1, level: 2,points:"1724.64,866.03 1813.05,857.4 1813.05,831.37 1744.03,831.37 1744.03,674.12 1740.92,673.12 1693.78,677.14 1693.78,661.27 1691.19,660.04 1634.34,664.45 1634.34,833.05 1724.64,833.05"},
            {status : 1, level: 3,points:"1573.31,872.56 1724.64,866.03 1724.64,833.05 1634.34,833.05 1634.34,633.76 1630.89,632.76 1575.81,638.29 1551.12,632.76 1551.12,609.76 1547.32,608.76 1446.44,621.02 1446.44,821.31 1573.31,821.31"},
            {status : 1, level: 4,points:"1165.19,893.19 1573.31,872.56 1573.31,821.31 1446.44,821.31 1446.44,547.94 1440.27,547.68 1380.87,559.04 1283.15,530.72 1283.15,495.15 1275.21,492.04 990.19,568.36 990.19,826.94 1165.19,826.94"},
            {status : 1, level: 5,points:"107.69,86.31 107.69,995.69 1165.19,893.19 1165.19,826.94 990.19,826.94 990.19,385.69 975.19,383.19 912.69,413.19 149.56,186.94 149.56,98.81"},
        ]
    },

    "t5-2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"217.13,72.65 148.24,323.35 148.24,926.81 1045.39,858.95 1045.39,724.27 914.34,724.27 911.34,597.71 785.95,602.55 785.95,339.41 696.79,331.64 696.79,163.29"},
            {status : 1, level: 1,points:"785.95 338.91 785.95 287.11 908.13 214.68 1218.92 274.22 1218.92 724.81 1526.6 724.81 1526.6 815.97 1045.39 858.45 1045.39 723.77 914.34 723.77 911.34 597.21 785.95 602.05 785.95 338.91"},
            {status : 1, level: 2,points:"1218.92 289.87 1415.41 242.79 1551.12 304.72 1551.12 514.33 1613.28 528.84 1613.28 585.13 1756.24 576.84 1756.24 797.05 1526.6 815.97 1526.6 724.81 1218.92 724.81 1218.92 289.87"},
            {status : 1, level: 3,points:"1551.12,350.03 1614.4,338.89 1707.38,380.33 1707.38,540.32 1726.8,542.72 1726.8,605.1 1865.1,598.66 1865.1,788.69 1756.24,797.55 1756.24,577.34 1613.28,585.63 1613.28,529.34 1551.12,514.83"},
            {status : 1, level: 4,points:"1707.38,392.24 1727.84,389.4 1799.58,420.73 1799.58,625.89 1916.13,621.89 1916.13,782.25 1865.1,788.69 1865.1,598.66 1726.8,605.1 1726.8,542.72 1707.38,540.32"},
        ]
    },

    "t4-1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"185.81,902.56 3.31,893.19 3.31,682.56 120.81,682.56 120.81,391.31 311.44,354.44 360.19,368.81 360.19,668.19 185.81,658.19"},
            {status : 1, level: 1,points:"523.94,924.44 185.81,902.56 185.81,658.19 360.19,668.19 360.19,341.94 607.69,293.81 675.81,323.19 675.81,653.19 523.94,638.19"},
            {status : 1, level: 2,points:"1006.44,955.69 523.94,924.44 523.94,638.19 675.81,653.19 675.81,276.94 1011.44,211.31 1222.06,360.06 1138.94,369.44 1138.94,620.56 1006.44,611.94"},
            {status : 1, level: 3,points:"1768.31,68.19 1768.31,1009.44 1006.44,955.69 1006.44,611.94 1138.94,620.56 1138.94,369.44 1222.06,360.06 1222.06,296.31 1238.31,293.19 1238.31,173.19"},
        ]
    },

    "t4-2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"185.81,902.56 3.31,893.19 3.31,682.56 120.81,682.56 120.81,391.31 311.44,354.44 360.19,368.81 360.19,668.19 185.81,658.19"},
            {status : 1, level: 1,points:"523.94,924.44 185.81,902.56 185.81,658.19 360.19,668.19 360.19,341.94 607.69,293.81 675.81,323.19 675.81,653.19 523.94,638.19"},
            {status : 1, level: 2,points:"1006.44,955.69 523.94,924.44 523.94,638.19 675.81,653.19 675.81,276.94 1011.44,211.31 1222.06,360.06 1138.94,369.44 1138.94,620.56 1006.44,611.94"},
            {status : 1, level: 3,points:"1768.31,68.19 1768.31,1009.44 1006.44,955.69 1006.44,611.94 1138.94,620.56 1138.94,369.44 1222.06,360.06 1222.06,296.31 1238.31,293.19 1238.31,173.19"},
        ]
    },

    "t4-3": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"185.81,902.56 3.31,893.19 3.31,682.56 120.81,682.56 120.81,391.31 311.44,354.44 360.19,368.81 360.19,668.19 185.81,658.19"},
            {status : 1, level: 1,points:"523.94,924.44 185.81,902.56 185.81,658.19 360.19,668.19 360.19,341.94 607.69,293.81 675.81,323.19 675.81,653.19 523.94,638.19"},
            {status : 1, level: 2,points:"1006.44,955.69 523.94,924.44 523.94,638.19 675.81,653.19 675.81,276.94 1011.44,211.31 1222.06,360.06 1138.94,369.44 1138.94,620.56 1006.44,611.94"},
            {status : 1, level: 3,points:"1768.31,68.19 1768.31,1009.44 1006.44,955.69 1006.44,611.94 1138.94,620.56 1138.94,369.44 1222.06,360.06 1222.06,296.31 1238.31,293.19 1238.31,173.19"},
        ]
    },

    "t4-4": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"185.81,902.56 3.31,893.19 3.31,682.56 120.81,682.56 120.81,391.31 311.44,354.44 360.19,368.81 360.19,668.19 185.81,658.19"},
            {status : 1, level: 1,points:"523.94,924.44 185.81,902.56 185.81,658.19 360.19,668.19 360.19,341.94 607.69,293.81 675.81,323.19 675.81,653.19 523.94,638.19"},
            {status : 1, level: 2,points:"1006.44,955.69 523.94,924.44 523.94,638.19 675.81,653.19 675.81,276.94 1011.44,211.31 1222.06,360.06 1138.94,369.44 1138.94,620.56 1006.44,611.94"},
            {status : 1, level: 3,points:"1768.31,68.19 1768.31,1009.44 1006.44,955.69 1006.44,611.94 1138.94,620.56 1138.94,369.44 1222.06,360.06 1222.06,296.31 1238.31,293.19 1238.31,173.19"},
        ]
    },

    "t5-1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1647.12,777.97 1813.56,777.97 1813.56,361.86 1604.99,348.39 1479.29,388.79 1479.29,543.31 1538.64,541.05 1538.64,600.09 1647.12,591.84"},
            {status : 1, level: 1,points:"1321.82,784.88 1647.12,777.97 1647.12,591.84 1538.64,600.09 1538.64,541.05 1479.29,543.31 1479.29,418.83 1479.29,333.54 1241.36,318.69 1177.13,351.84 1177.13,378.09 1177.13,485.25 1240.04,487.6 1240.33,595.29 1321.82,585.97"},
            {status : 1, level: 2,points:"953.36,793.17 1321.82,784.88 1321.82,585.97 1240.33,595.29 1240.04,487.6 1177.13,485.25 1177.13,378.09 1177.13,313.86 907.78,296.94 836.64,370.83 836.64,390.52 862.2,390.52 862.2,506.59 907.09,507.04 907.09,591.5 953.36,580.1 "},
            {status : 1, level: 3,points:"528.96,802.14 953.36,793.17 953.36,580.1 907.09,591.5 907.09,507.04 862.2,506.59 862.2,390.52 836.64,390.52 836.64,292.1 530.34,271.38"},
            {status : 1, level: 4,points:"100.07,242.72 100.07,808.71 528.96,802.14 528.96,373.25 471.98,370.83 471.64,361.51 449.88,265.51"},
        ]
    }
}