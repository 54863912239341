export const apiConst = {
    root : "https://wizioapi.com/touchscreen/koniagreen-api/public/",
    base : "https://wizioapi.com/touchscreen/koniagreen-api/public/api/",
    getProperties : function(){
        return fetch(apiConst.base + "properties",{method: 'get'}).then(response => response.json())
    },
    getBuildings : function(){
        return fetch(apiConst.base + "buildings",{method: 'get'}).then(response => response.json())
    },
    getLeadSources : function(){
        return fetch(apiConst.base + "leadSources",{method: 'get'}).then(response => response.json())
    },
    getLeadStatuses : function(){
        return fetch(apiConst.base + "leadStatuses",{method: 'get'}).then(response => response.json())
    },
    getRoomTypes : function(){
        return fetch(apiConst.base + "roomTypes",{method: 'get'}).then(response => response.json())
    },
    getStatuses : function(){
        return fetch(apiConst.base + "statuses",{method: 'get'}).then(response => response.json())
    },
    getBuildingTypes : function(){
        return fetch(apiConst.base + "buildingTypes",{method: 'get'}).then(response => response.json())
    },
    findPropsOnFloor : function(buildingId, flatLevel){
        return fetch(apiConst.base + "properties/buildingAndFlat/"+buildingId+"/"+flatLevel,{method: 'get'}).then(response => response.json())
    },
    getProperty : function(id){
        return fetch(apiConst.base + "properties/"+id,{method: 'get'}).then(response => response.json())
    },
    userLogin : function(fields){
        let data =  new FormData();
        data.append("email",fields["email"])
        data.append("password",fields["password"])
        return fetch(apiConst.base + "users/login", {method:'post', body: data}).then(response => response.json())
    },
    userForgotPassword : function(email){
        let data =  new FormData();
        data.append("email",email)
        return fetch(apiConst.base + "users/forgotPassword", {method:'post', body: data}).then(response => response.json())
    },
    userCheckResetToken : function(id,token){
        let data =  new FormData();
        data.append("id",id)
        data.append("token",token)
        return fetch(apiConst.base + "users/checkResetToken", {method:'post', body: data}).then(response => response.json())
    },
    userResetPassword : function(id,password){
        let data =  new FormData();
        data.append("id",id)
        data.append("password",password)
        return fetch(apiConst.base + "users/resetPassword", {method:'post', body: data}).then(response => response.json())
    },
    clientForgotPassword : function(email){
        let data =  new FormData();
        data.append("email",email)
        return fetch(apiConst.base + "clients/forgotPassword", {method:'post', body: data}).then(response => response.json())
    },
    clientCheck : function(email,user_id){
        let data =  new FormData();
        data.append("email",email)
        if(user_id) data.append("user_id",user_id)
        return fetch(apiConst.base + "clients/check", {method:'post', body: data}).then(response => response.json())
    },
    clientVerify : function(code,id){
        let data =  new FormData();
        data.append("code",code)
        data.append("id",id)
        return fetch(apiConst.base + "clients/verify", {method:'post', body: data}).then(response => response.json())
    },
    clientResendCode : function(id){
        let data =  new FormData();
        data.append("id",id)
        return fetch(apiConst.base + "clients/resendCode", {method:'post', body: data}).then(response => response.json())
    },
    clientCheckResetToken : function(id,token){
        let data =  new FormData();
        data.append("id",id)
        data.append("token",token)
        return fetch(apiConst.base + "clients/checkResetToken", {method:'post', body: data}).then(response => response.json())
    },
    clientResetPassword : function(id,password){
        let data =  new FormData();
        data.append("id",id)
        data.append("password",password)
        return fetch(apiConst.base + "clients/resetPassword", {method:'post', body: data}).then(response => response.json())
    },
    clientLogin : function(email,password){
        let data =  new FormData();
        data.append("email",email)
        return fetch(apiConst.base + "clientLogins", {method:'post', body: data}).then(response => response.json())
    },
    clientLoginCheck : function(id){
        let data =  new FormData();
        data.append("id",id);
        return fetch(apiConst.base + "clientLogins/check", {method:'post', body: data}).then(response => response.json())
    },
    clientLoginAccept : function(id, code){
        let data =  new FormData();
        data.append("id",id);
        data.append("code",code);
        return fetch(apiConst.base + "clientLogins/accept", {method:'post', body: data}).then(response => response.json())
    },
    clientLoginResend : function(id){
        let data =  new FormData();
        data.append("id",id);
        return fetch(apiConst.base + "clientLogins/resend", {method:'post', body: data}).then(response => response.json())
    },
    clientInvite : function(fields, user_id){
        let data =  new FormData();
        data.append("email",fields["email"]);
        data.append("user_id",user_id);
        return fetch(apiConst.base + "clientInvitations", {method:'post', body: data}).then(response => response.json())
    },
    clientInvitationCheck : function(id){
        let data =  new FormData();
        data.append("id",id);
        return fetch(apiConst.base + "clientInvitations/check", {method:'post', body: data}).then(response => response.json())
    },
    clientInvitationAccept : function(id, code){
        let data =  new FormData();
        data.append("id",id);
        data.append("code",code);
        return fetch(apiConst.base + "clientInvitations/accept", {method:'post', body: data}).then(response => response.json())
    },
    clientInvitationResend : function(id){
        let data =  new FormData();
        data.append("id",id);
        return fetch(apiConst.base + "clientInvitations/resend", {method:'post', body: data}).then(response => response.json())
    },
    clientCreate : function(fields, user_id,client_verification){
        let data =  new FormData();
        data.append("email",fields["email"]);
        data.append("password",fields["password"]);
        data.append("first_name",fields["first_name"]);
        data.append("last_name",fields["last_name"]);
        data.append("phone",fields["phone"] ? fields["phone"] : "0");
        data.append("lead_status_id",1);
        data.append("lead_source_id",1);
        data.append("user_id",user_id);
        data.append("client_verification",client_verification);
        return fetch(apiConst.base + "clients", {method:'post', body: data}).then(response => response.json())
    },
    clientUpdate : function(fields,id){
        let data =  new FormData();
        data.append('_method',"PUT");
        data.append("email",fields["email"]);
        data.append("first_name",fields["first_name"]);
        data.append("last_name",fields["last_name"]);
        data.append("phone",fields["phone"] ? fields["phone"] : "0");
        data.append("lead_status_id",fields["lead_status_id"]);
        data.append("lead_source_id",fields["lead_source_id"]);
        data.append("is_active",fields["is_active"]);
        return fetch(apiConst.base + "clients/"+id, {method:'post', body: data}).then(response => response.json())
    },
    presentationCreate : function(user_id,client_id,status){
        let data =  new FormData();
        data.append("user_id",user_id);
        data.append("client_id",client_id);
        data.append("status",status);
        return fetch(apiConst.base + "presentations", {method:'post', body: data}).then(response => response.json())
    },
    presentationTerminate : function(id){
        let data =  new FormData();
        return fetch(apiConst.base + "presentations/terminate/" + id, {method:'post', body: data}).then(response => response.json())
    },
    presentationDetailsMail : function(client_id){
        let data =  new FormData();
        data.append("client_id",client_id);

        return fetch(apiConst.base + "presentations/sendMail", {method:'post', body: data}).then(response => response.json())
    },
    findPropertyView : function(user_id,client_id,property_id){
        let data =  new FormData();
        data.append("user_id",user_id);
        data.append("client_id",client_id);
        data.append("property_id",property_id);
        return fetch(apiConst.base + "propertyViews/client/property",{method: 'post', body: data}).then(response => response.json())
    },
    propertyViewCreate : function(user_id,client_id,property_id){
        let data =  new FormData();
        data.append("user_id",user_id);
        data.append("client_id",client_id);
        data.append("property_id",property_id);
        return fetch(apiConst.base + "propertyViews", {method:'post', body: data}).then(response => response.json())
    },
    propertyViewUpdate : function(id,view_count){
        let data =  new FormData();
        data.append("view_count",view_count);
        data.append('_method',"PUT");

        return fetch(apiConst.base + "propertyViews/"+id, {method: 'post', body: data}).then(response => response.json())
    },
    favDelete : function(id){
        return fetch(apiConst.base + "propertyFavs/"+id,{method: 'delete'}).then(response => response.json())
    },
    favMultipleDelete : function(ids){
        let data =  new FormData();
        data.append("ids",ids);
        return fetch(apiConst.base + "propertyFavs/multipleDelete",{method: 'post',body:data}).then(response => response.json())
    },
    favCreate : function(client_id,property_id){
        let data =  new FormData();
        data.append("client_id",client_id);
        data.append("property_id",property_id);
        return fetch(apiConst.base + "propertyFavs", {method:'post', body: data}).then(response => response.json())
    },
    favPropertyAction : function(client_id,property_id,action){
        let data =  new FormData();
        data.append("client_id",client_id);
        data.append("property_id",property_id);
        data.append("action",action);
        return fetch(apiConst.base + "propertyFavs/action", {method:'post', body: data}).then(response => response.json())
    },
    propertyShare : function(client_id,properties){
        let data =  new FormData();
        data.append("client_id",client_id);
        data.append("properties",JSON.stringify(properties));
        return fetch(apiConst.base + "properties-share", {method:'post', body: data}).then(response => response.json())
    },

    propertyUpdate : function(id,fields){
        let data =  new FormData();
        data.append("user_id",fields.user_id);
        data.append("client_id",fields.client_id);
        data.append("presentation_id",fields.presentation_id);
        data.append("status_id",fields.status_id);
        data.append('_method',"PUT");
        return fetch(apiConst.base + "properties/"+id, {method:'post', body: data}).then(response => response.json())
    },
    propertyUpdatePriceOrStatus: function(id,fields){
        let data =  new FormData();
        data.append("price",fields.price);
        data.append("status_id",fields.status_id);
        data.append('_method',"PUT");
        return fetch(apiConst.base + "properties/updatePriceOrStatus/"+id, {method:'post', body: data}).then(response => response.json())
    },
    getClient : function(id){
        return fetch(apiConst.base + "clients/"+id,{method: 'get'}).then(response => response.json())
    },
    getClients : function(){
        return fetch(apiConst.base + "clients",{method: 'get'}).then(response => response.json())
    },
    getUsersByRole : function(role_id){
        return fetch(apiConst.base + "users/getUsersByRole/"+role_id,{method: 'get'}).then(response => response.json())
    },
    getUserClients : function(user_id){
        return fetch(apiConst.base + "clients/user/"+user_id,{method: 'get'}).then(response => response.json())
    },
    getUserPopularClients : function(user_id){
        return fetch(apiConst.base + "clients/popular/"+user_id,{method: 'get'}).then(response => response.json())
    },
    getUser : function(id){
        return fetch(apiConst.base + "users/"+id,{method: 'get'}).then(response => response.json())
    },
    getBrokers : function(){
        return fetch(apiConst.base + "users/getBrokers/all",{method: 'get'}).then(response => response.json())
    },
    getGeneralManagers : function(){
        return fetch(apiConst.base + "users/getGeneralManagers/all",{method: 'get'}).then(response => response.json())
    },
    getNewClientsByMonthAndYear : function(user_id){
        return fetch(apiConst.base + "users/newClientsByMonthAndYear/"+user_id,{method: 'get'}).then(response => response.json())
    },
    getPopularProperty : function(){
        return fetch(apiConst.base + "properties/popular/get",{method: 'get'}).then(response => response.json())
    },
    getPropertyActions : function(){
        return fetch(apiConst.base + "propertyActions",{method: 'get'}).then(response => response.json())
    },
    propertyActionCreate : function(fields){
        let data =  new FormData();
        data.append("user_id",fields.user_id);
        data.append("client_id",fields.client_id);
        data.append("property_id",fields.property_id);
        data.append("presentation_id",fields.presentation_id);
        data.append("status_id",fields.status_id);
        return fetch(apiConst.base + "propertyActions", {method:'post', body: data}).then(response => response.json())
    },
    propertyActionDelete : function(property_id){
        return fetch(apiConst.base + "propertyActions/"+property_id,{method: 'delete'}).then(response => response.json())
    },
    getClientNote : function(id){
        return fetch(apiConst.base + "clientNotes/"+id,{method: 'get'}).then(response => response.json())
    },
    clientNoteUpdate: function(client_id,fields){
        let data =  new FormData();
        data.append("note",fields.note);
        return fetch(apiConst.base + "clients/updateNote/"+client_id, {method:'post', body: data}).then(response => response.json())
    },
    clientNoteCreate: function(fields,client_id){
        let data =  new FormData();
        data.append("note",fields.note);
        data.append("client_id",client_id);
        return fetch(apiConst.base + "clientNotes", {method:'post', body: data}).then(response => response.json())
    },
    clientNoteDelete : function(id){
        return fetch(apiConst.base + "clientNotes/"+id,{method: 'delete'}).then(response => response.json())
    },
    getActionsByMonthAndYear : function(user_id){
        return fetch(apiConst.base + "users/actionsByMonthAndYear/"+user_id,{method: 'get'}).then(response => response.json())
    },
    userCreate : function(fields,parent_id,role_id){
        let data =  new FormData();
        data.append("email",fields["email"]);
        data.append("name",fields["name"]);
        data.append("phone",fields["phone"] ? fields["phone"] : "0");
        data.append("password",fields["password"]);
        data.append("parent_id",parent_id);
        data.append("role_id",role_id);
        return fetch(apiConst.base + "users", {method:'post', body: data}).then(response => response.json())
    },
    userUpdate : function(fields,id){
        let data =  new FormData();
        data.append('_method',"PUT");
        data.append("email",fields["email"]);
        data.append("name",fields["name"]);
        data.append("phone",fields["phone"] ? fields["phone"] : "0");
        data.append("password",fields["password"]);
        return fetch(apiConst.base + "users/"+id, {method:'post', body: data}).then(response => response.json())
    },
    userDelete : function(id){
        return fetch(apiConst.base + "users/"+id,{method: 'delete'}).then(response => response.json())
    },
    assingUserForClient : function(user_id,client_id){
        let data =  new FormData();
        data.append('_method',"PUT");
        data.append("user_id",user_id);
        return fetch(apiConst.base + "clients/assignUser/"+client_id, {method:'post', body: data}).then(response => response.json())
    },
    getRecentSells : function(){
        return fetch(apiConst.base + "propertyActions/recentSells/get", {method:'get'}).then(response => response.json())
    },
    getRevenueByMonthAndYear: function(){
        return fetch(apiConst.base + "propertyActions/revenueByMonthAndYear/get", {method:'get'}).then(response => response.json())
    },
    userUpdateParent : function(id,parent_id){
        return fetch(apiConst.base + "users/updateParent/"+id+"/"+parent_id, {method:'get'}).then(response => response.json())
    },
    getClientActions : function(id){
        return fetch(apiConst.base + "propertyActions/client/"+id,{method: 'get'}).then(response => response.json())
    },
    getClientViews : function(id){
        return fetch(apiConst.base + "propertyViews/client/"+id,{method: 'get'}).then(response => response.json())
    },

};


