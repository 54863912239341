import ProjectMarker from "../components/ProjectMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "Konia Green",
    currencyUnit: "€",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 10000,
        min: 370000,
        max: 1160000,
    },
    showIntroVideo : false,
    is360External : false,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "online@pafilia.com",
    isMultiCanvas : false,
    has360 : false,
    hasInfo : false,
    hasRealShot : false
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'Exterior',isExternal : false,link: '/gallery/exterior'},
            {id:2, title:'Interior',isExternal : false,link: '/gallery/interior'},
            {id:3, title:'Pafilia-Owned Plots',isExternal : false,link: '/gallery/plot'},
            {id:4, title:'Brochure',isExternal : false,link: '/gallery/brochure'},
        ],

        location_category : [
            {id : "restaurants",title : "Restaurants"},
            {id : "beach_bars",title : "Beach Bars"},
            {id : "banks",title : "Banks"},
            {id : "transportation",title : "Transportation"},
            {id : "schools",title : "Schools"},
            {id : "universities",title : "Universities"},
            {id : "health",title : "Health"},
            {id : "developments",title : "Pafilia Projects"},
        ],

        location : {
            center : [
                {id:0, title:"", lat: 34.779255845510136, lng:32.46778128716871}
            ],


            restaurants : [
                {id:1,title:'Minthis Restaurant',link:"/location/restaurants/1", lat:34.82300249248435, lng:32.4983042 },
                {id:2,title:'Konia Tavern',link:"/location/restaurants/2", lat:34.78622290727337, lng:32.450263966746384 },
                {id:3,title:'McDonald',link:"/location/restaurants/3", lat:34.77668057653477, lng:32.4438274810211 },
                {id:4,title:'The Arch3s',link:"/location/restaurants/4", lat:34.76020677280596, lng:32.452298355401226 },
                {id:5,title:'Anavargos Taverna',link:"/location/restaurants/5", lat:34.7940671383557, lng:32.451423168956396 },
                {id:6,title:'BUR-GER',link:"/location/restaurants/6", lat:34.776208933606526, lng:32.43791407747094 },
                {id:7,title:'Mothers Restaurant',link:"/location/restaurants/7", lat:34.75768981578372, lng:32.41261386410675 },
                {id:8,title:'Billys Restaurant',link:"/location/restaurants/8", lat:34.75203380752281, lng:32.422068449665524 },
                {id:9,title:'Zephyrus Bar & Restaurant',link:"/location/restaurants/9", lat:34.77669324097058, lng:32.443832830271596 },
                {id:10,title:'O Firis - Ο Φυρής',link:"/location/restaurants/10", lat:34.785102126983666, lng:32.45869397873516 },
                {id:11,title:'Honey Restaurant',link:"/location/restaurants/11", lat:34.77862716529843, lng:32.42061052567383 },
                {id:12,title:'Mamma Mia Restaurant',link:"/location/restaurants/12", lat:34.76813953084528, lng:32.41081791372057 },
                {id:13,title:'Riganato - Greek Grill Restaurant',link:"/location/restaurants/13", lat:34.776183117888856, lng:32.42568076707644 },

            ],

            beach_bars :  [

                {id:14,title:'Bar-M',link:"/location/beach_bars/14", lat:34.82403929999998, lng:32.498993557670694},
                {id:15,title:'Antasia',link:"/location/beach_bars/15", lat:34.750017294963385, lng:32.421806592554425},
                {id:16,title:'Cabana',link:"/location/beach_bars/16", lat:34.73403706506586, lng:32.45069530438357},
                {id:17, title:'Thea Beach Bar', link: '/location/beach_bars/17', lat:34.735461359028, lng:32.44741100135323},
                {id:18, title:'Riccos beach bar', link: '/location/beach_bars/18', lat:34.736252267743744, lng:32.44444035508099},
                {id:19,title:'Atlantida Beach Bar & Tavernt',link:"/location/beach_bars/19", lat:34.73670370604929, lng:32.44198688052183},
                {id:20,title:'Pool Bar',link:"/location/beach_bars/20", lat:34.73959262389083, lng:32.433776481751224},
                {id:21, title:'Alykes Beach Bar',link:"/location/beach_bars/21", lat:34.75487439596884, lng:32.41688118496696},

            ],

            banks : [
                {id:22,title:'Bank of Cyprus',link:"/location/banks/22", lat:34.78144952256643, lng:32.43590227246762 },
                {id:23,title:'Alpha Bank',link:"/location/banks/23", lat:34.78733008245525, lng:32.433189112651895 },
                {id:24,title:'AstroBank',link:"/location/banks/24", lat:34.78336632567313, lng:32.42894102934439 },
                {id:25,title:'Blueberry payment solutions',link:"/location/banks/25", lat:34.776356230034324, lng:32.42737232268961 },
                {id:26,title:'Euro Bank',link:"/location/banks/26", lat:34.77104390661498, lng:32.4308617639768 },
                {id:27,title:'Hellenic Bank',link:"/location/banks/27", lat:34.767393295752676, lng:32.41071931953722 },
            ],

            transportation : [
                {id:28,title:'Taxi Vangelis & Son',link:"/location/transportation/28", lat:34.79232719761732, lng:32.449394016290455},
                {id:29,title:'Andy Taxi, Paphos Cyprus',link:"/location/transportation/29", lat:34.787511254962325, lng:32.444581395084015},
                {id:30,title:'Demetris Taxis',link:"/location/transportation/30", lat:34.76157400768144, lng:32.43311642318266},
                {id:31,title:'Alfa Taxi Service',link:"/location/transportation/31", lat:34.790850528437716, lng:32.42530752296704},
                {id:32,title:'Intercity Buses',link:"/location/transportation/32", lat:34.778345068381384, lng:32.422716609272996},
                {id:33,title:'Taxi In Cyprus',link:"/location/transportation/33", lat:34.776362312259856, lng:32.41363787567103},
                {id:34,title:'Cyprus taxi services',link:"/location/transportation/34", lat:34.76803598571353, lng:32.41871641270088},
                {id:35,title:'Jack&Sons Transport Services LTD',link:"/location/transportation/35", lat:34.76260534844411, lng:32.41535117712299},
                {id:36,title:'Paphos',link:"/location/transportation/36", lat:34.77540119767062,lng:32.421784436089624},
                {id:37,title:'Paphos Get Taxi',link:"/location/transportation/37", lat:34.75689177889583, lng:32.422442000519304},
                {id:38,title:'Sams Taxi Paphos Cyprus',link:"/location/transportation/38", lat:34.755961184199315, lng:32.42086165380448},
                {id:39,title:'Anthos Taxi Paphos Cyprus',link:"/location/transportation/39", lat:34.75563810428704, lng:32.414310916128684},
                {id:40,title:'Kato Paphos Main Bus Station',link:"/location/transportation/40", lat:34.756384866593805, lng:32.411255984402175},
            ],

            schools : [
                {id:41,title:'Δημοτικό Σχολείο Κονιών',link:"/location/schools/41", lat:34.78487284341138, lng:32.45530455217907 },
                {id:42,title:'First Primary School',link:"/location/schools/42", lat:34.76613701961771, lng:32.45309084820437 },
                {id:43,title:'The Britons Private Institute',link:"/location/schools/43", lat:34.7654982090971, lng:32.45288231727801 },
                {id:44,title:'Yeroskipou High School',link:"/location/schools/44", lat:34.75503203239535, lng:32.45239001576659 },
                {id:45,title:'NOVA - Private Alternative Primary English School',link:"/location/schools/45", lat:34.76531720999369, lng:32.44465684964706 },
                {id:46,title:'Rosa Montessori School Paphos Cyprus',link:"/location/schools/46", lat:34.77244032996909, lng:32.44213896471205 },
                {id:47,title:'Little Baby Bubbles Kindergarten',link:"/location/schools/47", lat:34.7755263367115, lng:32.44166226104112 },
                {id:48,title:'Αριστείδειον Κέντρο Μελέτης',link:"/location/schools/48", lat:34.77676453220593, lng:32.440023100199625 },
                {id:49,title:'9th Elementary School',link:"/location/schools/49", lat:334.772374199174756, lng:32.43840756667182 },
                {id:50,title:'Paphos Institute of Maths and Sciences',link:"/location/schools/50", lat:34.77926014644368, lng:32.42962624057725 },
                {id:51,title:'Russian School Paphos',link:"/location/schools/51", lat:34.778261014021986, lng:32.4255552074447 },
                {id:52,title:'The Bulgarian School of Paphos',link:"/location/schools/52", lat:34.77899333449258, lng:32.425574860679426 },
                {id:53,title:'School of Music Arts',link:"/location/schools/53", lat:34.786020408916755, lng:32.42577893579834 },
                {id:54,title:'6th Municipal Primary School of Paphos',link:"/location/schools/54", lat:34.761174023723214, lng:32.417880129035936 },
                {id:55,title:'The International School Of Paphos',link:"/location/schools/55", lat:34.800472612294286, lng:32.45055081694511 },
            ],

            universities : [
                {id:56,title:'The American University of Beirut – Mediterraneo',link:"/location/universities/56", lat:34.772025081490305, lng:32.432700413333286 },
                {id:57,title:'ATC Automotive Technology Center',link:"/location/universities/57", lat:34.77297751540399, lng:32.44472660869437 },
                {id:58,title:'Alexander College/UK University Degrees',link:"/location/universities/58", lat:34.78671074158359, lng:32.44123833439576 },
                {id:59,title:'Cyprus University of Technology (ΤΕΠΑΚ)',link:"/location/universities/59", lat:34.77583276149691, lng:32.43401011240067 },
                {id:60,title:'Neapolis University Pafos',link:"/location/universities/60", lat:34.749225386576136, lng:32.42706137784175 },
            ],

            health : [
                {id:61,title:'WeCare Medical Centre',link:"/location/health/61", lat:34.774298254360076, lng:32.442874948359226 },
                {id:62,title:'Paphos General Hospital',link:"/location/health/62", lat:34.78867155299463, lng:32.445726328829515 },
                {id:63,title:'MEDICAL CLINICS',link:"/location/health/63", lat:34.78487696647616, lng:32.44176399468323 },
                {id:64,title:'Evangelismos Private Hospital',link:"/location/health/64", lat:34.78650246189341, lng:32.43797859167302 },
                {id:65,title:'Blue Cross Medical Center',link:"/location/health/65", lat:34.77646642598173, lng:32.4425640422424 },
                {id:66,title:'Saint George Private Hospital',link:"/location/health/66", lat:34.780710588845565, lng:32.430534291770755 },
                {id:67,title:'Royal Artemis Medical Center',link:"/location/health/67", lat:34.76697457092366, lng:32.443026905612825 },
                {id:68,title:'Iasis Private Hospital',link:"/location/health/68", lat:34.762793276804544, lng:32.438407042060106 },
                {id:69,title:'Paphos Hearing Screening Program',link:"/location/health/69", lat:34.774876472193434, lng:32.4263064869266 },
                {id:70,title:'Ygia Polyclinic Outpatient Services Center',link:"/location/health/70", lat:34.778679135950156, lng:32.42944907059254 },
                {id:71,title:'Pafos Medical 24',link:"/location/health/61", lat:34.74560382796939, lng:32.428646142321085 },
                {id:72,title:'Elpis Medical Centre',link:"/location/health/72", lat:34.762662260073206, lng:32.417736264828804 },
                {id:73,title:'Evangelismos Kings Medical Centre',link:"/location/health/73", lat:34.77227840414092, lng:32.40975486551666 },
                {id:74,title:'HIPPOCRATIC CLINIC Private Hospital',link:"/location/health/74", lat:34.78136810231831, lng:32.42631834490559 },

            ],

            developments : [
                {id:75,title:'Pafilia Plaza',link:"/location/developments/75", lat:34.761753365868024, lng:32.4164368674445},
                {id:76,title:'Enscape',link:"/location/developments/76", lat:34.71904231665992, lng:33.13763280678178 },
                {id:77,title:'Ava Plaza',link:"/location/developments/77", lat:34.77554075397102, lng:32.43007300349773 },
                {id:78,title:'Beachside',link:"/location/developments/78", lat:35.08864068327599,  lng:32.494887067458784 },
                {id:79,title:'Coral Vista',link:"/location/developments/79", lat:34.88689377183695, lng:32.35870726745011},
                {id:80,title:'Domus',link:"/location/developments/80", lat:34.83364844123452,  lng:32.43080010203373, },
                {id:81,title:'Konia Park 3',link:"/location/developments/81", lat:34.780148304985524,  lng:32.4677041523588 },
                {id:82,title:'The Grove',link:"/location/developments/82", lat:34.75006361115607,  lng:32.47028010920316 },
                {id:83,title:'Elysia Park',link:"/location/developments/83", lat:34.76246250973507,  lng:32.428591522608336 },
                {id:84,title:'ONE',link:"/location/developments/84", lat:34.68735099503021, lng:33.06489355521023 },
                {id:85,title:'MINTHIS',link:"/location/developments/85", lat:34.825643049549015, lng:32.49860547054945},
                {id:86,title:'Vida Suites',link:"/location/developments/86", lat:34.70556698810094,  lng:33.07687752408335 },
                {id:87,title:'Aria Residences',link:"/location/developments/87", lat:34.720838986412325,  lng: 33.14468468225328},
                {id:88,title:'Lana Villas',link:"/location/developments/88", lat:34.738338403241606, lng:33.08166456065576 },
                {id:89,title:'Olea Residences',link:"/location/developments/89", lat:34.88595926694794,  lng:32.35897418251022 },
                {id:90,title:'Aurai Residences',link:"/location/developments/90", lat:34.71803897314106, lng:33.076396538050254 },
                {id:91,title:'Anarita valley',link:"/location/developments/91", lat:34.738300940734966, lng:32.52994116210767 },
                {id:92,title:'Amathos',link:"/location/developments/92", lat:34.701251348680486, lng:33.09242506975134 },
                {id:93,title:'Amaya',link:"/location/developments/93", lat:34.765409793524995, lng:32.43713721961317 },
                {id:94,title:'Amarosa',link:"/location/developments/94", lat:35.044915689548866,  lng:32.36123851349333 },
                {id:95,title:'Hesperides gardens ',link:"/location/developments/95", lat:34.76610086940905, lng:32.42944891104027 },
                {id:96,title:'Nerina',link:"/location/developments/96", lat:34.794521296080696, lng: 32.407113916552106 },
                {id:97,title:'Pafia gardens',link:"/location/developments/97", lat:34.76970354878819, lng: 32.42077838246125 },
                {id:98,title:'Pafia 2',link:"/location/developments/98", lat:34.76748904789388,  lng: 32.41170208393252 },
                {id:99,title:'Pafia 3',link:"/location/developments/99", lat:34.768507842837295, lng: 32.4201431438783 },
                {id:100,title:'Pafia 5',link:"/location/developments/100", lat:34.76750848974157,  lng:32.412310029965106 },
                {id:101,title:'Pafilia gardens',link:"/location/developments/101", lat:34.7674618183608,  lng:32.43195742142809 },
                {id:102,title:'Polis gardens ',link:"/location/developments/102", lat:35.03517741776385, lng:32.435642602097055 },
                {id:103,title:'Prodromi gardens',link:"/location/developments/103", lat:35.025228340054525, lng:32.41258669341203},
                {id:104,title:'Anarita Chorio',link:"/location/developments/104", lat:34.744065211101855, lng:32.53891024101015 },
                {id:105,title:'Peyia Chorio',link:"/location/developments/105", lat:34.87850019211104, lng:32.37839335186083 },
                {id:106,title:'Oasis park',link:"/location/developments/106", lat:34.762269122512535, lng:32.436675538923865},
                {id:107,title:'Peyia gardens',link:"/location/developments/107", lat:34.88146204124502, lng:32.37435680552013},
                {id:108,title:'Lighthouse',link:"/location/developments/108", lat:34.765065895038646, lng:32.40979175774039 },
            ]



        }


    },
    gallery : {

        exterior : [
            {caption:'Exterior 1', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:'Exterior 2', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:'Exterior 3', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:'Exterior 4', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:'Exterior 5', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:'Exterior 6', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:'Exterior 7', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:'Exterior 8', source: require("../img/gallery/exterior/i8.jpg")},
        ],
        interior : [
            {caption:'Interior 1', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'Interior 2', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'Interior 3', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'Interior 4', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'Interior 5', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'Interior 6', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'Interior 7', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'Interior 8', source: require("../img/gallery/interior/i8.jpg")},


        ],

        plot : [
            {caption:'Pafilia-Owned Plots', source: require("../img/gallery/plot/i1.jpg")},
        ],

        facilities : [

        ],



    },
    brochure : [
        {image: require("../img/brochure/i1.webp")},
        {image: require("../img/brochure/i2.webp")},
        {image: require("../img/brochure/i3.webp")},
        {image: require("../img/brochure/i4.webp")},
        {image: require("../img/brochure/i5.webp")},
        {image: require("../img/brochure/i6.webp")},
        {image: require("../img/brochure/i7.webp")},
        {image: require("../img/brochure/i8.webp")},
        {image: require("../img/brochure/i9.webp")},
        {image: require("../img/brochure/i10.webp")},
        {image: require("../img/brochure/i11.webp")},
        {image: require("../img/brochure/i12.webp")},
        {image: require("../img/brochure/i13.webp")},
        {image: require("../img/brochure/i14.webp")},
        {image: require("../img/brochure/i15.webp")},
        {image: require("../img/brochure/i16.webp")},
        {image: require("../img/brochure/i17.webp")},
        {image: require("../img/brochure/i18.webp")},
        {image: require("../img/brochure/i19.webp")},
        {image: require("../img/brochure/i20.webp")},
        {image: require("../img/brochure/i21.webp")},
        {image: require("../img/brochure/i22.webp")},
        {image: require("../img/brochure/i23.webp")},
        {image: require("../img/brochure/i24.webp")},
        {image: require("../img/brochure/i25.webp")},
        {image: require("../img/brochure/i26.webp")},
        {image: require("../img/brochure/i27.webp")},
        {image: require("../img/brochure/i28.webp")},
        {image: require("../img/brochure/i29.webp")},
        {image: require("../img/brochure/i30.webp")},
        {image: require("../img/brochure/i31.webp")},
        {image: require("../img/brochure/i32.webp")},
        {image: require("../img/brochure/i33.webp")},
        {image: require("../img/brochure/i34.webp")},
        {image: require("../img/brochure/i35.webp")},
        {image: require("../img/brochure/i36.webp")},
        {image: require("../img/brochure/i37.webp")},
        {image: require("../img/brochure/i38.webp")},
        {image: require("../img/brochure/i39.webp")},
        {image: require("../img/brochure/i40.webp")},
        {image: require("../img/brochure/i41.webp")},
        {image: require("../img/brochure/i42.webp")},
        {image: require("../img/brochure/i43.webp")},
        {image: require("../img/brochure/i44.webp")},
        {image: require("../img/brochure/i45.webp")},
        {image: require("../img/brochure/i46.webp")},
        {image: require("../img/brochure/i47.webp")},
        {image: require("../img/brochure/i48.webp")},
        {image: require("../img/brochure/i49.webp")},
        {image: require("../img/brochure/i50.webp")},
        {image: require("../img/brochure/i51.webp")},
        {image: require("../img/brochure/i52.webp")},
        {image: require("../img/brochure/i53.webp")},
        {image: require("../img/brochure/i54.webp")},
        {image: require("../img/brochure/i55.webp")},

    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4,
        offer : 5,
        signed : 6,
        issue : 7,
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Blocked";
        if(id === 5)  str = "Offer / Negotiation";
        if(id === 6)  str = "Signed Contract";
        if(id === 7)  str = "Issue";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    lead_status : {
        new : 1,
        active : 2,
        lost : 3,
        inactive : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};