import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import UserContext from "../context/UserContext";
import {appConst} from "../../constant/appConst";
import {apiConst} from "../../constant/apiConst";
import LoadingOverlay from "../LoadingOverlay";
import DashboardManagerHome from "./DashboardManagerHome";
import DashboardGeneralHome from "./DashboardGeneralHome";

function DashboardHome() {
  const {user,setUser} = useContext(UserContext);


  const [data1,setData1] = useState(null);
  const [data2,setData2] = useState(null);
  const [data3,setData3] = useState(null);
  const [totals,setTotals] = useState({presentation : -1, client : -1, option : -1, sold : -1})
  useEffect(() => {
    if(user.role.id === appConst.role.sales_rep || user.role.id === appConst.role.broker)
    {
      apiConst.getUser(user.id).then(user => {
        let data_1 = {
          className: "sales-rep-property-actions",
          title: "Property Actions",
          headings: ["Date", "Property", "Client",  "Status"],
          rows: []
        };
        user.actions.forEach(action => {
          data_1.rows.push({
            date : action.date,
            property : {id : action.property_id,name: action.property_code},
            client: {id : action.client_id,name:action.client_name},
            status : appConst.getStatusTitle(action.status_id)
          })
        })
        setData1(data_1);

        let data_2 = {
          className: "recent-presentations",
          title: "Recent Presentations",
          headings: ["Date", "Client"],
          rows: []
        };
        user.presentations.forEach(presentation => {
          data_2.rows.push({date: presentation.date, client: {id: presentation.client_id, name :presentation.client_name}})
        })
        setData2(data_2);

        setTotals({
          presentation : user.totals.presentation,
          client : user.totals.client,
          option : user.totals.option,
          sold : user.totals.sold,
        });


      })

      apiConst.getPopularProperty().then(properties => {
        let data = {
          className: "most-popular-properties",
          title: "Popular Properties",
          headings: ["Property", "Favorite"],
          rows: []
        };
        properties.forEach(property => {
          data.rows.push({property:{id:property.id, name : property.code},favorite:property.favs.length})
        })
        setData3(data);

      })
    }

  },[])

  if(user.role.id === appConst.role.sales_manager)
    return (<DashboardManagerHome/>)
  else if(user.role.id === appConst.role.general_manager)
    return (<DashboardGeneralHome/>);
  else
  return (

    <section className="primary dashboard home">
      <DashboardHeader slug="home" />

        <div className="container">
        <div className="row">
          
          <div className="col-2">

              <NumberBox className="number-box" number={totals.presentation}  text="Activity" />
              <NumberBox className="number-box" number={totals.client}  text="Client" />
              <NumberBox className="number-box" number={totals.option}  text="Option" />
              <NumberBox className="number-box" number={totals.sold}  text="Sold" />




          </div>

         

          <div className="col-10">

            <div className="row">
              <div className="col-12">
                <DashboardTable data={data1} />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <DashboardTable data={data2} />
              </div>

              <div className="col-6">
                <DashboardTable data={data3} />
              </div>
            </div>
            
         </div>
          
        </div>
      </div>
    </section>

  );
  
}

export default DashboardHome;